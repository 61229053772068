var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "threads" },
    [
      _c(
        "h4",
        { staticClass: "threads__title section__title section__title--medium" },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _vm._v(" "),
      _vm.resultAmount
        ? _c("span", { staticClass: "threads__amount" }, [
            _vm._v(_vm._s(_vm.resultAmount)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "threads__tools" }, [
        _c(
          "div",
          { staticClass: "threads__search" },
          [
            _c("Search", {
              ref: "search",
              attrs: { placeholder: _vm.placeholder },
              on: { searched: _vm.onSearch },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "threads__pick" },
          [
            _c("Dropdown", {
              ref: "filter-category",
              attrs: { filter: _vm.filter.themes, options: _vm.mapping.themes },
              on: { filtered: _vm.onFiltered },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "threads__section" },
        _vm._l(_vm.resultSliced, function (result) {
          return _c("Thread", {
            ref: "results",
            refInFor: true,
            attrs: { data: result },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("Pagination", {
        ref: "pagination",
        attrs: { results: _vm.resultAmount, amount: 25, mod: _vm.pagination },
        on: { paginated: _vm.onPaginate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }