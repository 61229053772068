var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.render > 0 && _vm.results > _vm.offset
      ? _c(
          "div",
          { staticClass: "pagination" },
          [
            _c(
              "div",
              {
                staticClass:
                  "pagination__dot pagination__arrow pagination__arrow--prev",
                class: [_vm.hasModifier, _vm.hasPrev],
                on: {
                  click: function ($event) {
                    return _vm.setTab(_vm.index - 1)
                  },
                },
              },
              [
                _c(
                  "span",
                  { staticClass: "pagination__value" },
                  [_c("Left")],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.tabs, function (index) {
              return _c(
                "div",
                {
                  staticClass: "pagination__dot",
                  class: [_vm.hasModifier, _vm.isActive(index)],
                  on: {
                    click: function ($event) {
                      return _vm.setTab(index)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "pagination__value" }, [
                    _vm._v(_vm._s(index)),
                  ]),
                ]
              )
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "pagination__dot pagination__arrow pagination__arrow--next",
                class: [_vm.hasModifier, _vm.hasNext],
                on: {
                  click: function ($event) {
                    return _vm.setTab(_vm.index + 1)
                  },
                },
              },
              [
                _c(
                  "span",
                  { staticClass: "pagination__value" },
                  [_c("Right")],
                  1
                ),
              ]
            ),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.debug
      ? _c("div", [
          _c("span", { staticClass: "pagination__debug" }, [
            _vm._v(
              "Amount: " +
                _vm._s(_vm.results) +
                " | Index: " +
                _vm._s(_vm.index) +
                " | Render: " +
                _vm._s(_vm.render) +
                " | Start: " +
                _vm._s(_vm.start) +
                " | End: " +
                _vm._s(_vm.end)
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }