var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form__group" }, [
    _c("div", { staticClass: "select" }, [
      _c(
        "a",
        { staticClass: "select__trigger", attrs: { href: "javascript:;" } },
        [
          _c("span", { staticClass: "select__trigger-text" }, [
            _vm._v(
              "\n                " + _vm._s(_vm.filter.name) + " \n            "
            ),
          ]),
          _vm._v(" "),
          _c("Icon"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selected,
              expression: "selected",
            },
          ],
          attrs: { name: _vm.name },
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.selected = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              function ($event) {
                return _vm.onFilter(_vm.filter.label)
              },
            ],
          },
        },
        [
          _c("option", { attrs: { value: "" } }, [
            _vm._v(_vm._s(_vm.filter.name)),
          ]),
          _vm._v(" "),
          _c("option", { domProps: { value: 0 } }, [_vm._v("Selecteer alles")]),
          _vm._v(" "),
          _vm._l(_vm.options, function (option, key) {
            return _c("option", { key: key, domProps: { value: option.id } }, [
              _vm._v(_vm._s(option.name)),
            ])
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }