var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "16px",
        height: "14px",
        viewBox: "0 0 16 14",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "Symbols",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Icon-/-Heart",
                transform: "translate(-1.000000, -2.000000)",
                fill: "#181716",
                "fill-rule": "nonzero",
              },
            },
            [
              _c("g", { attrs: { id: "round-favorite_border-24px" } }, [
                _c("path", {
                  attrs: {
                    d: "M14.745,2.9925 C12.765,1.6425 10.32,2.2725 9,3.8175 C7.68,2.2725 5.235,1.635 3.255,2.9925 C2.205,3.7125 1.545,4.9275 1.5,6.21 C1.395,9.12 3.975,11.4525 7.9125,15.03 L7.9875,15.0975 C8.5575,15.615 9.435,15.615 10.005,15.09 L10.0875,15.015 C14.025,11.445 16.5975,9.1125 16.5,6.2025 C16.455,4.9275 15.795,3.7125 14.745,2.9925 L14.745,2.9925 Z M9.075,13.9125 L9,13.9875 L8.925,13.9125 C5.355,10.68 3,8.5425 3,6.375 C3,4.875 4.125,3.75 5.625,3.75 C6.78,3.75 7.905,4.4925 8.3025,5.52 L9.705,5.52 C10.095,4.4925 11.22,3.75 12.375,3.75 C13.875,3.75 15,4.875 15,6.375 C15,8.5425 12.645,10.68 9.075,13.9125 Z",
                    id: "Shape",
                  },
                }),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }