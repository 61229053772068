var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "reaction" }, [
    _c("form", { staticClass: "form reaction__form" }, [
      _c("div", { staticClass: "form__group" }, [
        _c("textarea", {
          ref: "area",
          staticClass: "reaction__area",
          attrs: { maxlength: "2000", placeholder: "Type hier je reactie..." },
        }),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn-solid--lightblue reaction__submit",
          attrs: { type: "submit" },
          on: { click: _vm.submit },
        },
        [_vm._v("Plaats reactie")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }