var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "card result-card results__row",
      attrs: { href: _vm.data.link },
    },
    [
      _c("div", { staticClass: "result-card__content" }, [
        _c("div", { staticClass: "meta meta--medium" }, [
          _c("span", { staticClass: "meta__avatar avatar" }, [
            _vm.hasAvatar(_vm.data.author.avatar)
              ? _c("img", {
                  staticClass: "avatar__image",
                  attrs: { src: _vm.data.author.avatar },
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.hasAvatar(_vm.data.author.avatar)
              ? _c("span", { staticClass: "avatar__initials" }, [
                  _vm._v(_vm._s(_vm.data.author.avatar)),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "meta__author" }, [
            _vm._v(_vm._s(_vm.data.author.name)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card__title result-card__title" }, [
          _vm._v(_vm._s(_vm.data.title)),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card__tags result-card__tags" },
        _vm._l(_vm.data.categories, function (tag) {
          return _c("span", { staticClass: "tag-solid--lightgrey" }, [
            _vm._v(_vm._s(tag)),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _vm.debug
        ? _c("div", { staticClass: "result-card__debug" }, [
            _vm._v(
              "Thema: " +
                _vm._s(_vm.data.themes) +
                " | Type: " +
                _vm._s(_vm.data.type)
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }