var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "card school-card threads__row",
      attrs: { href: _vm.data.link },
    },
    [
      _c("div", { staticClass: "school-card__content" }, [
        _c("div", { staticClass: "card__title school-card__name" }, [
          _vm._v(_vm._s(_vm.data.name)),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card__meta school-card__meta meta meta--small" },
          [
            _c("span", { staticClass: "meta__school" }, [
              _vm._v(_vm._s(_vm.data.type)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "meta__land meta--sep-dash" }, [
              _vm._v(_vm._s(_vm.data.country) + ", " + _vm._s(_vm.data.city)),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "thread-card__comments" }, [
        _c(
          "div",
          { staticClass: "avatar__collection" },
          [
            _vm._l(_vm.data.authors.avatars, function (author, index) {
              return _c(
                "span",
                {
                  staticClass: "avatar avatar--small",
                  style: _vm.setIndex(index),
                },
                [
                  _vm.hasAvatar(author)
                    ? _c("img", {
                        staticClass: "avatar__image",
                        attrs: { src: author },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.hasAvatar(author)
                    ? _c("span", { staticClass: "avatar__initials" }, [
                        _vm._v(_vm._s(author)),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            _vm._v(" "),
            _vm.data.authors.total > 0
              ? _c(
                  "div",
                  {
                    staticClass: "avatar avatar--small",
                    staticStyle: { transform: "translateX(0)", "z-index": "1" },
                  },
                  [
                    _c("span", { staticClass: "avatar__amount" }, [
                      _vm._v(_vm._s(_vm.data.authors.total) + "+"),
                    ]),
                  ]
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }